//Block
.lfg-favorites {
    width: 25%;
    margin-top: 15px;
    white-space: nowrap;
    display: none;
    @include above(750px) {
        text-align: right;
    }
}

#favorites-alert-link {
    display: none;
}

//Element
.lfg-favorites__link {
    font-size: 0.75rem;
    @include above(750px) {
        text-align: right;
        width: 100%;
    }
}

.myfavorites__header h4 {
    display: flex;
    justify-content: left;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    color: #fff;
    margin: 0;
    @media only screen and (max-width: 999px) and (min-width: 749px) {
        line-height: 22px;
    }
    .fa-star {
        margin-top: 2px;
        margin-right: 2px;
    }
}

.myfavorites__item {
    display: flex;
    justify-content: space-between;
    padding: 6px 20px;
    &:not(.myfavorites__item--noFav) {
        &:not(:last-of-type) {
            border-bottom: 1px solid #ccc;
        }
        &:focus {
            font-weight: 500;
            background: #fff;
        }
    }
    i.fas {
        color: #0d5da6;
        padding-top: 2px;
        height: 20px;
    }
    +.myfavorites__item {
        padding-top: 5px;
    }
    a {
        line-height: 20px;
        margin-right: 10px;
        -webkit-user-drag: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    .drag-icon {
        width: 18px;
        position: relative;
        float: left;
        margin-right: 5px;
        min-width: 18px;
        margin-top: 2px;
        &:focus {
            outline: -webkit-focus-ring-color auto 1px;
        }
    }
    @media only screen and (max-width: 749px) {
        .drag-icon {
            float: left;
            position: relative
        }
    }
    .drag-icon div.draggable-target {
        padding: 3px;
        margin: 1px;
        background-color: #0d5da6;
        float: left
    }
    .draggable-target {
        cursor: move;
    }
    .draggable-parent {
        background-color: white;
        list-style: none;
        display: inline-table;
    }
    &.drag {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, .35);
        /*.drag-icon div.draggable-target {
            background: #333333;
        }
        a {
            color: #666666;
        }
        i.fav-remove-link {
            color: #333333;
        }*/
    }
    &.dragover {
        border-top-width: 2px;
    }
    &.drop-target {
        padding: 10px;
    }
    &.ghost {
        opacity: 0;
    }
}

.myfavorites-component {
    .myfavorites-save-cancel {
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        text-align: right;
        border-bottom: 1px solid #ccc;
        background: #edf6f8;
        a {
            font-size: 14px;
            i {
                padding-right: 4px;
                font-size: 16px;
                vertical-align: text-top;
            }
            +a {
                padding-left: 10px;
            }
            &.saveChangesDisbleClass {
                color: #bfbfbf;
                pointer-events: none;
            }
        }
    }
    .fav-remove-link {
        cursor: pointer;
    }
    &.myfavorites {
        position: relative;
        touch-action: none;
    }
}

.myfavorites-component__item-link {
    width: 100%;
}

//Modifier
.u-global-container--breadcrumbs {
    display: flex;
    @include mobile() {
        padding: 0 20px !important;
    }
    .breadcrumbs {
        width: stretch;
    }
}

.lfg-favorites--disabled {
    .lfg-favorites__link {
        cursor: default;
        pointer-events: none;
    }
}

.lfg-favorites--add i.fas {
    display: none;
}

.lfg-favorites--remove i.far {
    display: none;
}

.lfg-favorites--banner {
    @include mobile() {
        margin-bottom: 15px;
    }
}

.u-global-container--favorites {
    .lfg-favorites {
        @include above(750px) {
            margin-left: auto;
        }
        @include mobile() {
            padding-left: 20px;
            margin-bottom: 30px;
        }
    }
}

.myfavorites__header--bg-blue {
    background: #0d5da6;
}

.myfavorites__items--bg-blue {
    background: #FFFFFF;
    .myfavorites__item,
    .myfavorites-save-cancel {
        background: #edf6f8;
    }
}

.myfavorites__btn {
    text-align: center;
    line-height: 30px;
    border-top: 1px solid #ccc;
    background: #edf6f8;
    display: none;
    * {
        font-size: 14px;
    }
    i {
        color: #B46226;
    }
}

.myfavorites__items--display-5+.myfavorites__btn {
    border-top: 0 none;
    position: relative;
    z-index: 10;
}

.myfavorites__item--drag {
    margin: -2px 0 2px 0;
    box-shadow: 1px 1px 5px #ccc;
    z-index: 100;
}

.myfavorites__item {
    &:last-of-type {
        &.myfavorites__item--drag {
            margin: 0px;
        }
    }
}

.myfavorites__items--display-5 {
    >li {
        &:not(:nth-of-type(-n+5)) {
            // display: none;
            opacity: 0;
            height: 0;
            margin: 0;
            padding: 0;
            border: 0;
            &:not(.myfavorites__item--noFav):not(:last-of-type) {
                border: 0;
            }
        }
        &:nth-of-type(5) {
            border-bottom: 0px;
        }
    }
    .myfavorites__btn {
        border-top: 0;
    }
}

.myfavorites__item--noFav {
    color: #37424a;
    display: none;
    font-style: italic;
    padding: 8px 20px;
    background: #edf6f8;
}

.myfavorites__item--touch-ghost {
    position: absolute;
    background: #fff;
    box-shadow: 1px 1px 5px #ccc;
}

//shortcuts
$shortcuts-header-bg: #0d5da6;
$shortcuts-items-bg: #edf6f8;