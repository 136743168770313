// Core
@import '../node_modules/@styles/lfg-styles/core/colors.scss';
@import '../node_modules/@styles/lfg-styles/core/config.scss';

@import '../node_modules/@styles/lfg-styles/mixins/breakpoints.scss';

// Component
@import 'app/styles/components/favorites.scss';





// Please do not delete the below code
.link-back {
    &:before {
        background: none;
        @include retina() {
            background: none;
        }
    }
}

.sidebar-nav__header--link-back {
    text-transform: none;
}


.topheader-submenu-container--favorites {
    padding: 0 !important;
}
